// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// Example: Load Rails libraries in Vite.
//
// import '@rails/ujs'
//
// import Turbolinks from 'turbolinks'
// import ActiveStorage from '@rails/activestorage'
//
// // Import all channels.
// import.meta.globEager('./**/*_channel.js')
//
// Turbolinks.start()
// ActiveStorage.start()

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// This file is automatically compiled by ~Webpack~ __vite__, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@assets/stylesheets/application.scss";

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import { start as activeStorageStart } from "@rails/activestorage";
import Cookies from "js-cookie";

// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) Rails.start(); // this might get loaded by @rails/activestorage
Turbolinks.start();
activeStorageStart();

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Import all channels.
import.meta.globEager("./**/*_channel.js");

// This is used to set Rails’s timezone for the user:
Cookies.set("browser.timezone", Intl.DateTimeFormat().resolvedOptions().timeZone, {
  expires: 365,
  path: "/",
  secure: true,
});
